/* You can add global styles to this file, and also import other style files */

// @font-face {
//   font-family: 'MyWebFont';
//   src: url(assets/front/DBFongNamX.ttf);
// }
table {
    position: fixed;
    top: 85px;
    left: 15px;
    .numText {
        color: white !important;
    }
}

body,
html {
    // font-family: 'MyWebFont', Fallback, sans-serif;
    font-family: "Sarabun", sans-serif !important;
}

// p,
// div{
//     font-size: 18px !important;
// }

.pnlm-controls-container {
    display: none;
}

.faq .p-dialog-content {
    padding-top: 30px !important;
    border-radius: 10px;
}

.product.p-dialog {
    box-shadow: unset;
    .p-dialog-content {
        background-color: unset;
    }
}

.video .p-dialog-header .p-dialog-title {
    font-size: 30px !important;
    color: #080808;
}

.iconWalk {
    // background-image: url("assets/images/icon/down-arrow2.png") !important;
    // background-size: cover;
    &.pnlm-hotspot.pnlm-scene {
        background-position: 0px 119px !important;
        background-size: 40px;
    }
    &.pnlm-hotspot {
        height: 40px !important;
        width: 40px !important;
        border-radius: 13px !important;
    }
}

.hotspot-title {
    display: flex;
    flex-direction: column;
    img {
        width: 40px;
        height: 40px;
    }
    span {
        font-size: 17px;
        background-color: #0006;
        color: white;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 50px;
        transform: translateX(calc(-50% + 20px));
        margin-top: 5px;
    }
}

.alert-set {
    width: 70% !important;
    .p-toast-message-icon {
        font-size: 50px !important;
    }
    .p-toast-detail,
    .p-toast-summary {
        font-size: 18px;
    }
}

.hotspot-title.none-text span {
    display: none;
}

.pnlm-about-msg {
    display: none !important;
}


/* width */

::-webkit-scrollbar {
    width: 10px;
    // background-color: #c2c2c2;
}


/* Track */

::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
    width: 10px;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #c2c2c2;
    border-radius: 10px;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: rgb(212, 212, 212);
}

.faq.p-dialog.show-img {
    height: inherit;
    .p-dialog-content {
        height: 100%;
    }
}

.collapse.in {
    display: none !important;
}

.collapse.show.in {
    display: block !important;
}

.law-head a span.p-tabview-title {
    font-size: 18px;
    font-family: "Sarabun", sans-serif !important;
}

.law-head a.p-ripple {
    text-decoration: none;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    color: #333 !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: #2196F3 !important;
}

.law-head a.p-ripple {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.p-tabview-panel p {
    font-size: 16px;
    font-family: "Sarabun", sans-serif !important;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    color: #333;
}

.p-tabview-panel {
    padding: 10px !important;
}

@media only screen and (max-width: 1400px) {
    .faq.p-dialog {
        width: 70vw !important;
    }
}

@media only screen and (max-width: 1200px) {
    .faq.p-dialog {
        width: 80vw !important;
    }
    .hotspot-title {
        span {
            font-size: 16px;
        }
    }
}

@media only screen and (max-width: 640px) {
    .video .p-dialog-header .p-dialog-title {
        font-size: 20px !important;
    }
    .hotspot-title {
        img {
            width: 35px;
            height: 35px;
        }
        span {
            font-size: 14px;
        }
    }
}

@media only screen and (max-width: 568px) {
    .faq.p-dialog .p-dialog-content {
        padding-top: 20px !important;
    }
}